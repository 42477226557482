import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

import Button from '@mui/material/Button';
import Blog from './components/Blog';

function App() {
  return (
    <div className="App">
      <div class="app-bar">
        <div class="logo-container">
          <img src="/logo.png" />
          <h1> Funraisers</h1>
        </div>
      </div>
      <Blog />
    </div>
  );
}

export default App;
