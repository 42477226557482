import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDm4bIvgi4n2HoV-r5kReDzn7BagIT6PZY",
    authDomain: "dolphin-funraisers.firebaseapp.com",
    projectId: "dolphin-funraisers",
    storageBucket: "dolphin-funraisers.appspot.com",
    messagingSenderId: "157419780282",
    appId: "1:157419780282:web:cd46d222cb9656aba3aa83",
    measurementId: "G-TNY19Z4QW5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);