import React, {useEffect, useState} from 'react';
import { query, where, orderBy, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Post from './Post';

const Blog = () => {
    const [posts, setPosts] = useState([]);

    const fetchPosts = async () => {
        const q = query(collection(db, "blog"), orderBy('index'));
        await getDocs(q)
        .then((querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id}));
            setPosts(newData);
            console.log('posts', newData);
        })
    }

    useEffect(() => {
        fetchPosts();
    }, [])

    return (
        <div>
            {
                posts.map((post) => {
                    return (<Post key={post.id} data={post} />);
                })
            }
        </div>
    )
}

export default Blog;