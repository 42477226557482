import React from "react";
const Post = (props) => {
    const backgroundStyle = props.data.header_image ? {
        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/dolphin-funraisers.appspot.com/o/${encodeURIComponent(props.data.header_image)}?alt=media&token=d8e75de8-60a7-4c2d-8c29-0ea115a70b54)`,
        minHeight: 300,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    } : {};
    const headerStyle = props.data.header_image ? { color: "#fff" } : { color: "#000" };
    
    return (
        <div className="section-full" style={backgroundStyle}>

            <div className="section-inner">
                <div className="section-header">
                    {props.data.name && (<h1 style={headerStyle}>{props.data.name}</h1>)}
                </div>
                
                {
                props.data.content.length > 0 && (
                <div className="section-content">
                    {
                        props.data.content.map((content, index) => {
                            console.log('content', content);
                            switch(content.type) {
                                case 'text':
                                    return (<p key={index}>{content.value}</p>);
                                case 'images':
                                    return (<img key={index} src={`https://firebasestorage.googleapis.com/v0/b/dolphin-funraisers.appspot.com/o/${encodeURIComponent(content.value[0])}?alt=media&token=d8e75de8-60a7-4c2d-8c29-0ea115a70b54)`} />);
                            }
                        })
                    }
                </div>
                )}
            </div>
        </div>
    )
}

export default Post;